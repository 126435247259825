export const ITEMS_PER_PAGE = 60;
export const QUICK_ADD_PARTS = [
  { value: 446, label: "4x4 Axle Actuator" },
  { value: 682, label: "AC Compressor" },
  { value: 679, label: "AC Condenser" },
  { value: 253, label: "Air Bag" },
  { value: 435, label: "Axle Assembly, Rr" },
  { value: 434, label: "Axle Assy, Fr (4WD)" },
  { value: 438, label: "Axle Flange" },
  { value: 437, label: "Axle Housing" },
  { value: 499, label: "Axle Parts, Misc" },
  { value: 447, label: "Axle Shaft" },
  { value: 106, label: "Bumper Absorber, Fr" },
  { value: 193, label: "Bumper Absorber, Rr" },
  { value: 105, label: "Bumper Assy, Front" },
  { value: 190, label: "Bumper Assy, Rear" },
  { value: 101, label: "Bumper Cover, Fr" },
  { value: 187, label: "Bumper Cover, Rr" },
  { value: 145, label: "Bumper End Cap" },
  { value: 192, label: "Bumper Filler Panel" },
  { value: 107, label: "Bumper Reinf, Front" },
  { value: 108, label: "Bumper Shock" },
  { value: 119, label: "Cowl" },
  { value: 170, label: "Decklid Tailgate" },
  { value: 120, label: "Door Assembly, Fr" },
  { value: 277, label: "Door Glass, Front" },
  { value: 278, label: "Door Glass, Rear" },
  { value: 300, label: "Engine Assembly" },
  { value: 351, label: "Engine Cover" },
  { value: 343, label: "Engine Mounts" },
  { value: 318, label: "Engine Oil Cooler" },
  { value: 399, label: "Engine Parts, Misc." },
  { value: 344, label: "Engine Pulley, Misc" },
  { value: 648, label: "Engine Wire Harness" },
  { value: 110, label: "Fender" },
  { value: 111, label: "Fender Flare/Ext" },
  { value: 113, label: "Fender Moulding" },
  { value: 500, label: "Frame" },
  { value: 503, label: "Frame Horn" },
  { value: 502, label: "Frame Rails, Up Lwr" },
  { value: 501, label: "Frame, Front" },
  { value: 646, label: "Fuse Box, Engine" },
  { value: 104, label: "Grille" },
  { value: 114, label: "Headlamp Assembly" },
  { value: 117, label: "Hood" },
  { value: 118, label: "Hood Hinge" },
  { value: 121, label: "Hood Strut" },
  { value: 475, label: "Ind Rr Susp Assy" },
  { value: 112, label: "Inner Fender" },
  { value: 160, label: "Quarter Panel Assy" },
  { value: 527, label: "Strut" },
  { value: 528, label: "Strut Tower Brace" },
  { value: 523, label: "Susp Trunion Arm" },
  { value: 166, label: "Tail Lamp" },
  { value: 315, label: "Timing Chain, Belt" },
  { value: 400, label: "Transmiss,Transaxle" },
  { value: 413, label: "Transmission Pump" },
  { value: 560, label: "Wheel" },
  { value: 561, label: "Wheel Bearing Front" },
  { value: 562, label: "Wheel Bearing Rear" },
  { value: 570, label: "Wheel Cover" },
  { value: 269, label: "Windshield Frame" },
  { value: 270, label: "Windshield Glass" },
];
