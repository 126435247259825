<template>
  <div>
    <b-modal id="modal-prevent-closing" ref="modal" :title="$t('confirmationDialog.print_bar_code')" centered no-close-on-backdrop hide-footer
      @show="showModal" @hidden="resetModal" @hide="resetModal">
      <form ref="form" @submit.stop.prevent="save" id="print">
        <div class="box-border">
          <div class="center-of-box">
            <div class="inner-circle">
              <div class="tiny_circle"></div>
            </div>
          </div>
          <div class="center-of-box">
            <barcode v-bind:value="data.id" width="3" height="60" displayValue="false">
              Show this if the rendering fails.
            </barcode>
          </div>
          <div v-if="data.company_name">
            <div class="uppercase_bold">{{ data.company_name }}</div>
          </div><br>
          <div class="display-flex" v-if="data.vin && data.profile_type == 'auction'">
            <div class="left-side uppercase_bold">VIN</div>
            <div class="right-side uppercase_text">{{ data.vin }}</div>
          </div>
          <div class="display-flex" v-if="data.part_name">
            <div class="left-side uppercase_bold">PART NAME</div>
            <div class="right-side">{{ data.part_name }}</div>
          </div>
          <div class="display-flex" v-if="data.year">
            <div class="left-side uppercase_bold">YEAR</div>
            <div class="right-side">{{ data.year }}</div>
          </div>
          <div class="display-flex" v-if="data.make">
            <div class="left-side uppercase_bold">MAKE</div>
            <div class="right-side">{{ data.make }}</div>
          </div>
          <div class="display-flex" v-if="data.model">
            <div class="left-side uppercase_bold">MODEL</div>
            <div class="right-side">{{ data.model }}</div>
          </div>
          <div class="display-flex" v-if="data.recommended_sale_price">
            <div class="left-side uppercase_bold">PRICE</div>
            <div class="right-side">{{ data.recommended_sale_price | USCurrencyfilter }}</div>
          </div>
          <div class="display-flex" v-if="data.interchange">
            <div class="left-side uppercase_bold">INTERX</div>
            <div class="right-side">{{ data.interchange }}</div>
          </div>
          <div class="display-flex" v-if="data.part_grade">
            <div class="left-side uppercase_bold">GRADE</div>
            <div class="right-side">{{ data.part_grade }}</div>
          </div>
          <div class="display-flex" v-if="data.warranty_days">
            <div class="left-side uppercase_bold">WARRANTY</div>
            <div class="right-side">{{ data.warranty_days }}</div>
          </div>
          <div class="display-flex" v-if="data.miles">
            <div class="left-side uppercase_bold">MILES</div>
            <div class="right-side">{{ data.miles }}</div>
          </div>
          <div class="display-flex" v-if="data.description">
            <div class="left-side uppercase_bold">{{ data.profile_type != 'auction' ? 'LOCATION & ' : '' }}NOTES</div>
            <div class="right-side">{{ data.description }}</div>
          </div>
          <div class="display-flex" v-if="data.created_at">
            <div class="left-side uppercase_bold">DATE</div>
            <div class="right-side">{{ data.created_at | MMDDYYdatefilter }}</div>
          </div>
        </div>
      </form>
      <div class="d-block text-center">
        <b-button class="mt-4 btn custom-btn custom-danger" variant="outline-danger" @click="resetModal">
          {{ $t('confirmationDialog.close') }}
        </b-button>

        <b-button class="ml-2 mt-4 btn custom-btn pattern-btn" @click="handleOk">
          <span>{{ $t('confirmationDialog.print') }}</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { between, maxLength, required, requiredIf } from "vuelidate/lib/validators";
import { showFailure, showSuccess } from "@/eventbus/action";
import $ from "jquery";
import EventBus from "@/eventbus";
import VueBarcode from 'vue-barcode';

export default {
  components: {
    'barcode': VueBarcode
  },
  props: {
    printPartId: {
      type: String,
      required
    }, printCarProfileId: {
      type: String
    }, printDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {
        profile_type: null,
        company_name: null,

        id: null,
        part_name: null,
        year: null,
        make: null,
        model: null,
        recommended_sale_price: '',
        interchange: null,
        part_grade: null,
        warranty_days: null,
        miles: null,
        description: null,
        created_at: null,
        vin: null,
        sales_price: null,

        // part_location: null,
        // yard_location: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      part: "partlist/getPart",
      interchange: "partlist/getInterchange",
    }),
  },
  watch: {
    printDialog(val) {
      if (val) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
    printPartId(val) {
      if (val) {
        this.getPartById();
      }
    }
  },
  methods: {
    ...mapActions({
      partsById: "partlist/getPartsById",
      getInterchange: "partlist/getInterchange",
      getPart: "partlist/getPart",
    }),

    async getPartById() {
      try {
        this.$store.commit("setLoader", true);
        let printCarProfileId = '';
        if (this.printCarProfileId) {
          printCarProfileId = this.printCarProfileId;
        } else {
          printCarProfileId = this.$route.params.id ? this.$route.params.id : this.$route.params.uid;
        }

        await this.getPart({
          id: this.printPartId,
          carProfileId: printCarProfileId
        });

        if (this.part.car_part) {
          //Common
          this.data.id = this.part.car_part.id;
          this.data.year = this.part.car_profile.year;
          this.data.make = this.part.car_profile.make;
          this.data.model = this.part.car_profile.model;
          this.data.company_name = this.part.car_profile.user.user_detail.company_name;

          //parts
          this.data.part_name = this.part.car_part.part_name;
          this.data.recommended_sale_price = this.part.car_part.recommended_sale_price;
          this.data.interchange = this.part.car_part.interchange_code;
          this.data.part_grade = this.part.car_part.part_grade;
          this.data.warranty_days = this.part.car_part.warranty_days;
          this.data.created_at = this.part.car_part.created_at;
          // this.data.part_location = this.part.car_part.part_location;
          // this.data.yard_location = this.part.car_part.yard_location;

          //CAR PROFILE
          this.data.vin = this.part.car_profile.vin;
          this.data.sales_price = this.part.car_profile.sales_price;
          this.data.profile_type = this.part.car_profile.profile_type;
          this.data.miles = this.part.car_profile.miles;

          if (this.part.car_profile.profile_type == 'auction' && this.part.car_profile.notes) {
            this.data.description = this.part.car_profile.notes;
          } else {
            this.data.description = this.part.car_part.description;
          }
        }
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    showModal() {
      this.$refs["modal"].show();
    },
    hideModal() {
      EventBus.$emit("resetDialog");
      this.$refs["modal"].hide();
    },

    resetModal() {
      this.hideModal();
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler

      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
          <style>
          #print {
            font-size: 13px;
          }
          .center-of-box {
            margin: auto;
            display: table;
          }
          .display-flex {
            display: flex;
          }
          .left-side {
            width: 40%;
          }
          .right-side {
            width: 60%;
          }
          .padding10 {
            padding: 10px;
          }
          .box-border {
            margin: auto;
            border: 3px #000 solid;
            width: 377px;
            height: 454px;
            padding: 10px 30px;
          }
          .uppercase_bold {
            text-transform: uppercase;
            font-weight: bold;
          }
          .uppercase_text {
            text-transform: uppercase;
          }
          .inner-circle {    
            border-radius: 50%;
            width: 30px;
            border: 4px #000 solid;
          }
          .tiny_circle {
            margin: auto;
            border-radius: 50%;
            height: 23px;
          }
          </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

  }
}
</script>
